import React from 'react';
import { TextVariant } from '@naf/text';

import S from '../styles';
import { LayoutWithMainContent } from '../../../components/layout/Layout';
import { DEFAULT_IMG_URL } from '../../../lib/constants';
import { useScript } from '../../../hooks/useScript';
import { cookieDeclarationPath } from '../../../components/ThemedApp';
import { InternalSearchCardType } from '../../../components/layout/InternalSearchMetaData';

export const CookieDeclaration = () => {
  useScript('https://consent.cookiebot.com/ae694d96-971c-4934-989d-a178a94d2f5f/cd.js', 'CookieDeclaration');

  return (
    <LayoutWithMainContent
      title="Cookies"
      description="Oversikt over våre cookies"
      imgUrl={DEFAULT_IMG_URL}
      gtmArgs={{
        page_type: 'Cookies',
        pageCategory: '',
        contentId: cookieDeclarationPath,
      }}
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
    >
      <S.Header variant={TextVariant.Header1}>Våre cookies</S.Header>
    </LayoutWithMainContent>
  );
};
